.debug {
  border: 1px solid red;
}
.team {
  display: grid;
  justify-content: center;
  padding: 10px;
  margin-top: 5rem;
  grid-gap: 30px;
  gap: 30px;
  max-width: 1200px;
}
.cont {
  display: flex;
}
.cont1 {
  position: relative;
}
.container_all {
  position: relative;
}
.whatsapp {
  position: fixed;
  z-index: 10;
  right: 2.5rem;
  bottom: 2.5rem;
  display: grid;
  place-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.partners_container {
  display: flex;
  flex-wrap: wrap;
  place-items: center;
  justify-content: center;
}
@media (max-width: 868px) {
  /* Adjust the max-width value to your breakpoint */
  .w-auto {
    width: 400px !important;
  }
}

.whatsappUs {
  background-color: #f3f3f3;

  border-radius: 0.75rem;
  border: hidden;
}

a {
  text-decoration: none;
}
.whatsapp img {
  width: 3rem;
  height: 3rem;
}
.buttonA {
  position: absolute;
  bottom: -20px; /* Adjust as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center by moving half of its own width */
  width: inherit;
  border: none;
  font-size: larger;
  font-weight: 600;
  padding: 10px 45px;
  border-radius: 100vw;
}
.team:nth-child(0){
  background-color: red;
}
@media screen and (min-width: 600px) {
  .team {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 800px) {
  .team {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

